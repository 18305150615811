<template>
  <div
    class="radio-box"
  >
    <div :class="field.properties.basic.inOneLine ? 'block' : ''">
      <b-radio
        v-for="(choice, cIndex) in field.properties.basic.choices"
        :key="cIndex"
        v-model="selectedValue"
        :name="choice"
        class="is-12"
        :class="{
          'checked': values[field.id] === choice, 'column': !field.properties.basic.displayOptionsInRow
        }"
        size="is-small"
        :native-value="choice"
        @click.native="handleInput(choice,values[field.id])"
        v-on="fieldEvents"
      >
        {{ choice }} 
      </b-radio>
    </div>
  </div>
</template>

<script >
import { computed } from '@vue/composition-api';
import { debounce } from 'lodash';
const __sfc_main = {};
__sfc_main.props = {
  field: {
    type: Object,
    required: true
  },
  values: {
    type: Object,
    required: true
  },
  errors: {
    type: Object,
    required: true
  },
  fieldEvents: {
    type: Object,
    default: () => ({})
  },
  customEventHandler: {
    type: Function,
    default: () => {}
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const selectedValue = computed({
    get() {
      return props.values[props.field.id];
    },
    set(value) {
      emit('update', {
        value: value
      });
      if (props.customEventHandler) {
        props.customEventHandler();
      }
    }
  });
  const handleInput = debounce((newValue, oldValue) => {
    if (newValue === oldValue) {
      selectedValue.value = '';
    }
  }, 300);
  return {
    selectedValue,
    handleInput
  };
};
export default __sfc_main;
</script>
